<template>
  <header>
    <div class="header-layer" :class="{'active' : isMenuOpen}" @click="isMenuOpen = false"></div>
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-6 align-self-center">
          <RouterLink to="/" class="logo">
            <img src="../assets/images/logo.svg" alt="logo">
          </RouterLink>
        </div>
        <div class="col-md-9 col-6 align-self-center">
          <div class="navbar-menu" :class="{'active' : isMenuOpen}">
            <RouterLink to="/" class="logo">
              <img src="../assets/images/logo.svg" alt="logo">
            </RouterLink>
            <ul class="menu">
              <li><a href="#">ABOUT</a></li>
              <li><a href="#">WHAT WE DO</a></li>
              <li><a href="#">RESIDENTIAL</a></li>
              <li><a href="#">COMMERCIAL</a></li>
              <li><a href="#">CALCULATOR</a></li>
              <li><a href="#" class="btn-style">REQUEST A QUOTE</a></li>
            </ul>
          </div>
          <div class="hamburger-menu">
            <img src="../assets/images/icons/menu-bar.png" @click="isMenuOpen = true" alt="icon">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderView',
  props: {
    msg: String,
  },
  data() {
    return {
      isMenuOpen: false,
    }
  },
});
</script>
