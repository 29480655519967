<template>
  <div class="home">

    <HeaderView/>

    <section class="sec-what-we-do">
      <div class="container">
        <div class="img-wrapper">
          <img src="../assets/images/what-we-do-img.png" alt="img">
          <div class="do-content">
            <h2 class="title">What We Do</h2>
            <h2 class="regular">We are driven by one goal:
              to create spaces <br> that inspire people to
              do amazing things.</h2>
          </div>
        </div>
        <div class="content-wrapper">
          <h3 class="h3"><strong>iFlowspace</strong> is not just a platform
            that outsources everything to partners; we work
            closely with each professional as an in-house team
            to provide comprehensive business and residential
            solutions. From finding a cozy house or stylish office,
            to space planning, design, build, and even post-completion
            aftercare, you will only need to deal with <strong class="italic">one dedicated team</strong>.</h3>
        </div>
      </div>
    </section>

    <section class="sec-design-build">
      <div class="container small">
        <h2>What We Do</h2>
        <div class="design-build-wrapper">
          <div class="build-card highlight">
            <h3>Design & Build</h3>
            <p>Comprehensive management
              of your project from initial
              concept to final construction.</p>
          </div>

          <div class="build-card">
            <h3>Office Space</h3>
            <p>Creating functional and aesthetically
              pleasing work environments tailored to
              your business needs.</p>
          </div>
          <div class="build-card">
            <h3>Refurbishment</h3>
            <p>Modernizing and revitalizing existing
              spaces or your commercial lot to enhance
              their appeal and functionality.</p>
          </div>
          <div class="build-card">
            <h3>Construction</h3>
            <p>Delivering high-quality building
              services for various types of projects,
              ensuring durability and excellence.</p>
          </div>

          <div class="build-card">
            <h3>Home Renovation</h3>
            <p>Transforming your home to reflect
              your style and improve your living
              experience.</p>
          </div>
          <div class="build-card">
            <h3>Space Planning</h3>
            <p>Efficiently organizing and optimizing your
              space for maximum usability and comfort
              including landscape planning.</p>
          </div>
          <div class="build-card">
            <h3>Interior & Styling</h3>
            <p>We’ll consult and collaborate before
              providing you with detailed sketches,
              plans and 3D renders to help you visualise
              your space.</p>
          </div>

          <div class="build-card">
            <h3>Property Search</h3>
            <p>Assisting in finding the perfect
              property that meets your personal,
              business or investment needs.</p>
          </div>
          <div class="build-card">
            <h3>Carpentry</h3>
            <p>Providing custom carpentry solutions
              with exceptional craftsmanship and attention
              to detail.</p>
          </div>
          <div class="build-card">
            <h3>Coworking/Serviced</h3>
            <p>Designing and building dynamic coworking
              environments that foster collaboration and
              maximize space profit potential.</p>
          </div>
          <div class="build-card">
            <h3>Furniture</h3>
            <p>Sourcing and installing quality furniture,
              curtains and home appliance to complete your
              space with style and functionality.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="sec-build-commercial">
      <div class="container">
        <ul class="tabs">
          <li><a href="#" v-on:click.prevent="activeTab='1'" v-bind:class="[ activeTab === '1' ? 'active' : '' ]">Commercial</a></li>
          <li><a href="#" v-on:click.prevent="activeTab='2'" v-bind:class="[ activeTab === '2' ? 'active' : '' ]">Residential</a></li>
        </ul>
        <div class="row" v-if="activeTab ==='1'">
          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-1.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Pavilion Hilltop</p>
                <p>Medium Bedroom</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-2.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Workplace 4,500 sf</p>
                <p>Guest Corner</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-3.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Coworking Space 6,500 sf</p>
                <p>Common Area</p>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-4.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Office Space  6,000 sf</p>
                <p>Lounge Area</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-5.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Corner Shoplot   20,000 sf</p>
                <p>Design & Refurbishment</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6">
            <div class="commercial-card">
              <div class="img-wrapper">
                <img src="../assets/images/commercial-card-img-6.png" alt="img">
              </div>
              <div class="content-wrapper">
                <p class="title">Wellness Centre</p>
                <p>Lounge Area</p>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <a href="#" class="btn-style text f-24 capitalize">View More</a>
        </div>
      </div>
    </section>

    <section class="sec-consultant">
      <div class="container">
        <ul class="consultant-galley">
          <li>
            <a href="#">
              <img src="../assets/images/consultant-galley-img-1.png" alt="img">
            </a>
          </li>
          <li>
            <a href="#">
              <img src="../assets/images/consultant-galley-img-2.png" alt="img">
            </a>
          </li>
          <li>
            <a href="#">
              <img src="../assets/images/consultant-galley-img-3.png" alt="img">
            </a>
          </li>
          <li>
            <a href="#">
              <img src="../assets/images/consultant-galley-img-4.png" alt="img">
            </a>
          </li>
        </ul>
        <div class="content-wrapper">
          <h3 class="medium">We handle projects of all sizes,
            from small tasks to large architectural endeavors. <br> <br>
            Speak with our consultant, and we will assign a
            professional to cater to your specific property
            needs.</h3>
          <a href="#" class="btn-style white-border">TALK TO OUR CONSULTANT</a>
        </div>
      </div>
    </section>

    <FooterView/>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      activeTab: '1',
      isMenuOpen: false,
    }

  },
});
</script>
