<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-md-6">
          <div class="footer-content">
            <h3 class="small white">iFlowspace Sdn Bhd <br>
              28-3 Jalan PJU 5/4 <br>
              Dataran Sunway, Kota Damansara, <br>
              Petaling Jaya, 47810 Selangor. <br>
              Whatsapp : +6 010 888 5055
            </h3>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="company-wrapper">
            <h3 class="small white title">COMPANY</h3>
            <ul class="link">
              <li><a href="#">About</a></li>
              <li><a href="#">FAQs</a></li>
              <li><a href="#">Career</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Calculator</a></li>
              <li><a href="#">Partnership</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-md-12">
          <div class="form-wrapper">
            <h3 class="small white title">GET LATEST
              NEWS ON <br> PROPERTY & DESIGN</h3>
            <div class="form">
              <div class="form-group">
                <input type="email" class="form-control" placeholder="Enter your email address">
              </div>
              <div class="form-group">
                <a href="#" class="btn-style transparent white">Sign Up</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-md-12">
          <div class="social-wrapper">
            <h3 class="small white title">KEEP IN TOUCH</h3>
            <a href="mailto:hello@iflowspace.com" class="mail">hello@iflowspace.com</a>
            <ul class="social">
              <li><a href="#"><img src="../assets/images/icons/instagram-white-icon.svg" alt="icon"></a></li>
              <li><a href="#"><img src="../assets/images/icons/linkedIn-white-icon.svg" alt="icon"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderView',
  props: {
    msg: String,
  },
});
</script>
